import { isMobile } from "react-device-detect"

import { aemAxiosInstance } from "@/constants/api"
import {
  apimSubscriptionKey,
  apiBaseUrl,
  authorBaseUrl,
  PDP_END_POINT_SUBSCRIPTION_KEY,
  PLP_END_POINT,
  PLP_END_POINT_SUBSCRIPTION_KEY,
} from "@/constants"
import { getUserPersona } from "@/utils/helper"
import {
  getProductDetailsBySku,
  getProductsBySku,
} from "@/components/sharedCart/shareCartHelper"
import {
  addCompareProducts,
  clearProduct,
  updateCompareStatus,
} from "@/store/features/compareSlice"
import { store } from "@/store"
import { apim } from "@/constants/api"

const isServer = typeof window === "undefined"

const getListFromServer = async (
  profileName,
  persona,
  brand = "kohler-india"
) => {
  return new Promise(async (resolve, reject) => {
    try {
      aemAxiosInstance.defaults.headers.common[
        "Ocp-Apim-Subscription-Key"
      ] = `${apimSubscriptionKey}`
      const { data: content } = await aemAxiosInstance({
        url: `${authorBaseUrl}${PLP_END_POINT_SUBSCRIPTION_KEY}?profilename=profile_${brand}_PLP_${profileName}&persona=${persona}&collections=${brand}&rows=3`,
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

const getListFromClient = async (profileName, persona, accessToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      aemAxiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`
      const { data: content } = await aemAxiosInstance({
        url: `${authorBaseUrl}${PLP_END_POINT}?profilename=${profileName}&persona=${persona}`,
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

const getPlpParams = (obj, targetKey) => {
  let result = null
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === targetKey) {
        return obj[key]
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        result = getPlpParams(obj[key], targetKey)

        if (result !== null) {
          return result
        }
      }
    }
  }
  return result
}

const getSearchItems = async (
  searchKey,
  accessToken,
  displayName = "SEARCH"
) => {
  return new Promise(async (resolve, reject) => {
    try {
      aemAxiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`
      const { data: content } = await aemAxiosInstance({
        url: `${authorBaseUrl}${PLP_END_POINT}?displayname=${displayName}&q=${searchKey}`,
      })
      resolve(content)
    } catch (error) {
      reject(error)
    }
  })
}

const formatListingData = async (data, stripResponse) => {
  return new Promise((resolve, reject) => {
    try {
      const persona = getUserPersona()
      const payload = {
        facets: data.facet_counts?.facet_fields,
        facetLabels: data.fusion?.facet_labels ?? [],
        grouped:
          data.fusion?.isCollapsePLP === "true" ||
          data.fusion?.isCollapsePLP === true,
        fusion: data.fusion,
      }

      const priceRange = payload.grouped
        ? data.facet_counts?.facet_ranges[
            `discountedPriceStartingAt.${persona}_d`
          ]
        : data.facet_counts?.facet_ranges[`priceList.${persona}.finalPrice_d`]
      if (priceRange) {
        const gap = priceRange.gap
        // eslint-disable-next-line camelcase
        payload.facets.Price_Range = priceRange.counts.map(item =>
          typeof item === "string"
            ? `[${parseInt(item)} TO ${parseInt(item) + gap}]`
            : item
        )
      }

      // mark multiselect
      payload.facets = formatMultiSelectFacets(data)
      // set color swatches
      payload.colors = data.fusion?.color_swatch_labels ?? {}

      if (data.response) {
        if (stripResponse && data.response.docs.length > 3) {
          payload.data = data.response.docs.slice(0, 3)
        } else {
          payload.data = data.response.docs
        }

        payload.total = data.response.numFound
        payload.start = data.response.start
        if (
          data.fusion?.isCollapsePLP === "true" ||
          data.fusion?.isCollapsePLP === true
        ) {
          payload.grouped = true
        }
      } else {
        payload.data = data.grouped[Object.keys(data.grouped)[0]].groups.map(
          doc => doc.doclist.docs[0]
        )
        payload.total = data.grouped[Object.keys(data.grouped)[0]].groups.length
        payload.start = 0
        payload.grouped = true
      }
      resolve(payload)
      // update
      //   ? dispatch({ type: GET_MORE_PRODUCTS, payload: data.response.docs })
      //   : dispatch({ type: GET_PRODUCTS, payload })

      // dispatch({ type: STOP_LOADING, payload: "plp" })
    } catch (error) {
      reject(error)
    }
  })
}

const formatMultiSelectFacets = data => {
  const facets = Object.entries(data.facet_counts?.facet_fields ?? {}).map(
    fields => ({ [fields[0]]: fields[1] })
  )
  let facetField = data?.fusion["facet.field"]
  if (facetField) {
    if (typeof facetField === "string") facetField = [facetField]
    facetField.forEach(item => {
      facets.forEach((filter, index) => {
        if (filter[item]) {
          const obj = {
            [`**${item}`]: filter[item],
          }
          facets.splice(index, 1, obj)
        }
      })
    })
  }
  const result = Object.assign({}, ...facets)
  return result
}

const getRowsInitialValue = () => {
  let rowsInitialValue = 30
  if (!isServer) {
    rowsInitialValue = isMobile
      ? 30
      : sessionStorage.rows &&
        JSON.parse(sessionStorage.rows)[window.location.pathname]
      ? JSON.parse(sessionStorage.rows)[window.location.pathname]
      : 30
  }
  return rowsInitialValue
}

const addToCompareProduct = (sku, isBackOrder, imgUrl, category) => {
  store.dispatch(updateCompareStatus(true))
  getProductDetailsBySku([sku], category)
    .then(async resp => {
      resp.forEach(vsku => {
        store.dispatch(
          addCompareProducts({
            sku: vsku,
            backorder: isBackOrder,
            compareSku: sku,
            imgUrl,
          })
        )
      })
    })
    .catch(error => {
      store.dispatch(updateCompareStatus(false))
    })
}

const removeFromCompareProduct = sku => {
  store.dispatch(clearProduct(sku))
}

const getProductDetailsFromServer = async (profileName, collections, slug) => {
  return new Promise(async (resolve, reject) => {
    try {
      aemAxiosInstance.defaults.headers.common[
        "Ocp-Apim-Subscription-Key"
      ] = `${apimSubscriptionKey}`
      const { data: { response: { docs = [] } = {} } = {} } =
        await aemAxiosInstance({
          url: `${apiBaseUrl}${PDP_END_POINT_SUBSCRIPTION_KEY}?profilename=${profileName}&collections=${collections}&slug=${slug}`,
        })

      if (docs[0]) {
        const data = docs[0]
        const prodName = data["ProductBrandNameDisplay_s"]
          ? data["ProductBrandNameDisplay_s"]
          : data["productName_s"]
        const prodDesc = data["ProductDescriptionProductShort_s"]
          ? data["ProductDescriptionProductShort_s"]
          : ""
        const prodSku = data["CustomerFacingSKU_s"]
        const prodCategory = data["ProductLocalCategory_s"]

        data.metaTitle_s = `Buy ${prodName} ${prodDesc} ${prodSku} Online at Kohler India`
        data.metaDescription_s = `Shop ${prodName} ${prodCategory} ${prodSku}. Discover our wide selection of high-quality Bathroom and Kitchen accessories available at Kohler India`
      }

      resolve(docs[0] ?? {})
    } catch (error) {
      reject(error)
    }
  })
}

const getLineItemAttrs = async lineItems => {
  let query = ""

  lineItems?.forEach((item, i) => {
    if (item) {
      let sku = item?.variant?.sku || item?.sku || ""
      if (!sku) {
        sku = item?.sku || item?.variant?.sku
      }
      query += `"${sku}"`
      if (i + 1 !== lineItems.length) query += ", "
    }
  })
  try {
    if (query) {
      const { data } = await apim.get(`/search/plp`, {
        params: {
          fl: "sku_s,ctId_s,Color.SKU.Details_ss,productName_s,ProductBrandNameDisplay_s,productImage.labelWithUrl_ss,slug_s,Product_Category, RegionSupplyChain_ss",
          q: "*:*",
          profilename: "profile_kohler-india_General",
          collections: "kohler-india",
          fq: `sku_s:(${query})`,
          rows: 50,
          noCollapse: true,
        },
      })
      const {
        response: { docs = [] },
      } = data
      const updatedLineItems = lineItems.map(item => ({
        ...item,
        lwData:
          docs.find(
            product => product["sku_s"] === (item?.sku || item?.variant?.sku)
          ) || [],
      }))
      return updatedLineItems
    }
  } catch (err) {
    console.error("Failed to fetch product details", err)
  }
  return []
}

const getProductAttr = (sku = "", lwData = {}) => {
  const attr = {}
  const colorAttr = lwData["Color.SKU.Details_ss"]
  const imgAttr = lwData["productImage.labelWithUrl_ss"]
  const brandAttr = lwData["ProductBrandNameDisplay_s"]
  if (colorAttr) {
    const res = colorAttr.find(d => d.includes(sku))
    if (res) {
      const a = res.split("|")
      attr.finishName = a[0]
      attr.fileName = a[1]
      attr.customerFacingSku = a[5]
    }
  }
  if (imgAttr) {
    const res = imgAttr.find(d => d.includes(sku))
    if (res) {
      const a = res.split("|")
      attr.image = a[5]
    }
  }
  if (brandAttr) {
    attr.brandName = brandAttr
  }
  return attr
}

const getProductBySku = async sku => {
  try {
    const res = await apim.get(
      `/catalog/product/projection?where=masterVariant(sku%3D"${sku}")`
    )
    if (res && res.data && res.data.results?.length) return res.data.results[0]
  } catch (err) {
    console.error("Failed to fetch product details", err)
  }
}

const getPriceByPersona = (data, sku) => {
  let prices = []
  const persona = getUserPersona()

  if (data.masterVariant.sku === sku) {
    prices = data.masterVariant.prices
  } else if (data.variants.length) {
    const product = data.variants.find(item => item.sku === sku)
    prices = product?.prices
  }

  const price = prices.find(p => p.customerGroup?.obj?.key === persona)
  return price.value?.centAmount / 100 || 0
}
const getMRPprice = (data, sku) => {
  let prices = []

  if (data.masterVariant.sku === sku) {
    prices = data.masterVariant.prices
  } else if (data.variants.length) {
    const product = data.variants.find(item => item.sku === sku)
    prices = product?.prices
  }

  const mrpprice = prices.find(p => !p.customerGroup)
  return mrpprice.value?.centAmount / 100 || 0
}

const formatBundleItems = async (bundle = [], list = []) => {
  try {
    const skus = bundle.map(item => item["sku_s"])
    const ctData = await getProductsBySku(skus)

    const data = []

    bundle.forEach(item => {
      let sku = item["sku_s"]
      let ctId = item["ctId_s"]
      let slug = item["slug_s"]
      let category = item["ProductLocalCategory_s"]
      let color = item["ColorFinish_s"]
      let img = ""
      let productName = item["ProductDescriptionProductShort_s"]
      let qty = list.find(item => item.startsWith(sku)).split("|")[1]

      const ctAttributes = ctData.find(item => item.id === ctId)
      let price = getPriceByPersona(ctAttributes, sku) * Number(qty || 1)
      let mrpprice = getMRPprice(ctAttributes, sku) * Number(qty || 1)

      const imgAttr = item["productImages.labelWithUrl_ss"]
      if (imgAttr) {
        const res = imgAttr.find(d => d.includes(sku))
        if (res) {
          const a = res.split("|")
          img = a[5]
        }
      }

      const additionalInfo = {
        country: item["SKUCountryofOrigin_s"],
        packageContent: item["ProductPackageContents_s"],
        length: item["ProductOverallLengthCm_d"],
        width: item["ProductOverallWidthCm_d"],
        height: item["ProductOverallHeightCm_d"],
      }

      if (item["RegionSupplyChain_ss"] && item["RegionSupplyChain_ss"].length) {
        additionalInfo.regionSupplyChain = item["RegionSupplyChain_ss"][0]
      }

      data.push({
        img,
        productName,
        color,
        sku,
        qty,
        price,
        mrpprice,
        slug,
        category,
        additionalInfo,
      })
    })

    return data
  } catch (ex) {
    console.log(ex)
  }
}

export {
  getListFromServer,
  getListFromClient,
  getPlpParams,
  getSearchItems,
  formatListingData,
  getRowsInitialValue,
  addToCompareProduct,
  removeFromCompareProduct,
  getProductDetailsFromServer,
  getLineItemAttrs,
  getProductAttr,
  getProductBySku,
  formatBundleItems,
}
